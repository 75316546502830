<template>
  <div>
    <svg width="1060" height="800" class="container-border"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import UserService from "../store/UserService";

export default {
  name: "TrustExplorer",
  data() {
    return {
      loading: true,
      certificates: [],
      zoom: false,
      svg: null
    };
  },
  computed: {
    names_and_keys: function() {
      return [];
    },
    edges: function() {
      // these are the certs (lines)
      let result = [];
      for (let x of this.certificates) {
        if (x.issuer_public_key) {
          result.push({
            source: x.issuer_public_key,
            target: x.public_key.id,
            sourceid: x.issuer_public_key,
            targetid: x.public_key.id,
            value: 0.05,
            relation: ""
          });
        }
      }
      return result;
    },
    nodes: function() {
      // these are the dots (public keys)
      let result = [];
      let exist = [];
      for (let x of this.certificates) {
        if (x in exist) {
          //do nothing
        } else {
          result.push({
            id: x.public_key
          });
        }
      }
      return result;
    }
  },
  methods: {
    get_certificates() {
      UserService.getLocalApi(
        "certificates?groups=true&is_ca=True&size=10000"
      ).then(response => {
        this.certificates = response.data.results;
        this.draw_sim();
      });
    },
    draw_sim() {
      // Define the container

      this.zoom = d3.zoom().scaleExtent([12 / 2, 25]);

      let marge = { top: 60, bottom: 60, left: 60, right: 60 };
      this.svg = d3.select("svg").call(this.zoom);
      let width = this.svg.attr("width");
      let height = this.svg.attr("height");

      var t = d3.zoomIdentity.translate(0, 0).scale(1);
      this.svg.call(this.zoom.transform, t);

      let g = this.svg
        .append("g")
        .attr("transform", "translate(" + marge.top + "," + marge.left + ")");

      let colorScale = d3
        .scaleOrdinal()
        .domain(d3.range(this.nodes.length))
        .range(d3.schemeCategory10);

      let chargeStrength = Math.min(this.edges.length / 100, 1) * 880 - 1000;

      let forceSimulation = d3
        .forceSimulation()
        .force(
          "charge",
          d3
            .forceManyBody()
            .strength(chargeStrength)
            .distanceMin(0)
            .distanceMax(950)
            .theta(0.0)
        )
        .force("x", d3.forceX().strength(0.1))
        .force("y", d3.forceY().strength(0.1))
        .force("link", d3.forceLink())
        .force("collide", d3.forceCollide().radius(10))
        // .force("charge", d3.forceManyBody())
        .force("center", d3.forceCenter(width / 2 - 150, height / 2 - 100));

      forceSimulation.nodes(this.nodes).on("tick", ticked);

      forceSimulation
        .force("link")
        .links(this.edges)
        .distance(function(d) {
          return d.value * 1000;
        });
      forceSimulation
        .force("center")
        .x(width / 2)
        .y(height / 2);

      let links = g
        .append("g")
        .selectAll("line")
        .data(this.edges)
        .enter()
        .append("line")
        .attr("stroke", function(d, i) {
          return colorScale(i);
        })
        .attr("stroke-width", 1);

      let linksText = g
        .append("g")
        .selectAll("text")
        .data(this.edges)
        .enter()
        .append("text")
        .text(function(d) {
          return d.relation;
        });

      let gs = g
        .selectAll(".circleText")
        .data(this.nodes)
        .enter()
        .append("g")
        .attr("transform", function(d) {
          let cirX = d.x;
          let cirY = d.y;
          return "translate(" + cirX + "," + cirY + ")";
        });

      gs.append("circle")
        .attr("r", 6)
        .attr("fill", function(d, i) {
          return colorScale(i);
        });

      gs.append("text")
        .attr("x", -10)
        .attr("y", -20)
        .attr("dy", 10)
        .text(function(d) {
          return d.name;
        });
      function linkArc(d) {
        var dx = d.target.x - d.source.x,
          dy = d.target.y - d.source.y,
          dr = Math.sqrt(dx * dx + dy * dy),
          offsetX = (dx * d.target.radius) / dr,
          offsetY = (dy * d.target.radius) / dr,
          theta = Math.atan2(dy, dx) + Math.PI / 7.85,
          d90 = Math.PI / 2;
        dx = dx - offsetX;
        dy = dy - offsetY;
        var test1 = d.target.x - offsetX;
        var test2 = d.target.y - offsetY;

        if (isNaN(test1)) {
          return null;
        }
        if (isNaN(test2)) {
          return null;
        }

        var dtxs = test1 - 10 * Math.cos(theta),
          dtys = test2 - 10 * Math.sin(theta);

        return (
          "M" +
          d.source.x +
          "," +
          d.source.y +
          "A" +
          dr +
          "," +
          dr +
          " 0 0 1," +
          test1 +
          "," +
          test2 +
          "A" +
          dr +
          "," +
          dr +
          " 0 0 0," +
          d.source.x +
          "," +
          d.source.y +
          "M" +
          dtxs +
          "," +
          dtys +
          "l" +
          (3.5 * Math.cos(d90 - theta) - 5 * Math.cos(theta)) +
          "," +
          (-3.5 * Math.sin(d90 - theta) - 5 * Math.sin(theta)) +
          "L" +
          (dtxs - 3.5 * Math.cos(d90 - theta) - 5 * Math.cos(theta)) +
          "," +
          (dtys + 3.5 * Math.sin(d90 - theta) - 5 * Math.sin(theta)) +
          "z"
        );
      }
      function ticked() {
        var test = linkArc;

        if (test) {
          links
            .attr("d", test)
            .attr("x1", function(d) {
              return d.source.x;
            })
            .attr("y1", function(d) {
              return d.source.y;
            })
            .attr("x2", function(d) {
              return d.target.x;
            })
            .attr("y2", function(d) {
              return d.target.y;
            });
        }
        gs.attr("transform", function(d) {
          return "translate(" + d.x + "," + d.y + ")";
        });
        // update label positions
        linksText
          .attr("x", function(d) {
            return d.x;
          })
          .attr("y", function(d) {
            return d.y;
          });
      }
    }
  },
  mounted() {
    this.get_certificates();
  }
};
</script>

<style scoped></style>
